import React from "react"
import * as PropTypes from "prop-types"
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

const propTypes = {
  data: PropTypes.object.isRequired,
}

class Ogp001JaPage extends React.Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Cookin' Burgerで遊んでいるよ！</title>
          <meta name="description" content="誰でも簡単に遊べるゲーム「Cookin' Burger」でDEAPcoinがゲットできちゃう！？気になった人はさっそく一緒に遊んでみよう！" />

          <meta property="og:image" content="https://cookinburger.com/assets/img/ogp.png" />
          <meta property="og:title" content="Cookin' Burgerで遊んでいるよ！" />
          <meta property="og:description" content="誰でも簡単に遊べるゲーム「Cookin' Burger」でDEAPcoinがゲットできちゃう！？気になった人はさっそく一緒に遊んでみよう！" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="Cookin' Burgerで遊んでいるよ！" />
          <meta name="twitter:description" content="誰でも簡単に遊べるゲーム「Cookin' Burger」でDEAPcoinがゲットできちゃう！？気になった人はさっそく一緒に遊んでみよう！" />
          <meta name="twitter:image" content="https://cookinburger.com/assets/img/ogp.png" />
        </Helmet>
      </div>
    )
  }
}

Ogp001JaPage.propTypes = propTypes

export default Ogp001JaPage

export const pageQuery = graphql`
  query PageJaOgp01Query {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`